import React from 'react';
import Header from '../components/Header';

const Contact = () => {
  return (
    <div>
      <Header />
      Contact
    </div>
  );
};

export default Contact;
